import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';


const styles = require('./Addons-styles.module.css');

const Columns = () => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className={styles.columns}>
          <div className={styles.col}>
            <h1>Addons</h1>
            <h2>Browser extensions</h2>
            <p>
              Superposition has browser addons for Chrome and Firefox. With them you can quickly open the current page
              in Superposition, or right click any link and send it to Superposition.
            </p>

            <ul>
              <li>
                <a href="https://chrome.google.com/webstore/detail/superposition-helper/dkabagejkcmeakglamklmcloggfpbfgi">
                  For Chrome
                </a>
              </li>
              <li>
                <a href="https://addons.mozilla.org/en-US/firefox/addon/superposition-helper/">For Firefox</a>
              </li>
            </ul>
          </div>
          <div className={styles.col}>
            <h1>&nbsp;</h1>
            <h2>Plugin for Adobe XD</h2>
            <p>
              Use the design tokens from Superposition directly in Adobe XD using our Panel plugin. Select any object
              and the applicable tokens will be shown, like colors and typography. Clicking on them automatically
              applies them to your selection.
            </p>

            <p>
              <a href="/xd-plugin">
                More on our Adobe XD Plugin
              </a>
            </p>

            <h2>Plugin for Figma</h2>
            <p>
              Use design tokens from inside Superposition directly in Figma using our plugin. Open the plugin window and
              select any object and the applicable tokens will be shown, like colors and typography. Clicking on them
              automatically applies them to your selection.
            </p>

            <p>
              <a href="/figma-plugin/">More on our Figma Plugin</a>
            </p>
          </div>
        </section>
      )}
    />
  );
};

export const query = graphql`
  query {
    adobeFund: file(relativePath: { eq: "images/adobe-fund.png" }) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    polypane: file(relativePath: { eq: "images/polypane.svg" }) {
      publicURL
    }
    xd: file(relativePath: { eq: "images/xd.svg" }) {
      publicURL
    }
    sketch: file(relativePath: { eq: "images/sketch.svg" }) {
      publicURL
    }
    figma: file(relativePath: { eq: "images/figma.svg" }) {
      publicURL
    }
    css: file(relativePath: { eq: "images/css.svg" }) {
      publicURL
    }
    sass: file(relativePath: { eq: "images/sass.svg" }) {
      publicURL
    }
    js: file(relativePath: { eq: "images/js.svg" }) {
      publicURL
    }
    swift: file(relativePath: { eq: "images/swift.svg" }) {
      publicURL
    }
    android: file(relativePath: { eq: "images/android.svg" }) {
      publicURL
    }
  }
`;

export default Columns;
